import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import { Button, Carousel } from "react-bootstrap";
import { Layout } from "element-react";
import Product from "../Product";
import { actionPropertyList } from "../../redux/actions/property";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = state => {
  const { propertyData } = state.property;
  return {
    propertyData
  };
};

const mapDispatchToProps = {
  actionPropertyList
};

const Home3 = connect(mapStateToProps, mapDispatchToProps)(class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      products3: [],
      home3CriticalWidth: 720,
      width: window.innerWidth,
    };
  }

  productGroup = [];

  handleResize = (windowSize, event) => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    this.setState({ width: window.innerWidth });

    this.props.actionPropertyList(21).then(() => {
      this.setState({ products: this.props.propertyData });
    }).then(() => {
      let products = this.state.products ? this.state.products : [];

      // Sorting logic based on hoursToPublish and updatedAt
      products.sort((a, b) => {
        // Condition for Coming Soon (hoursToPublish > 0)
        if (a.hoursToPublish > 0 && b.hoursToPublish <= 0) return -1; // a comes first
        if (a.hoursToPublish <= 0 && b.hoursToPublish > 0) return 1; // b comes first

        // If both are Coming Soon, sort by hoursToPublish
        if (a.hoursToPublish > 0 && b.hoursToPublish > 0) {
          return a.hoursToPublish - b.hoursToPublish; // Ascending order
        }

        // Condition for unpublished items (e.g., based on updatedAt)
        if (!a.isPublished && b.isPublished) return -1; // a comes first
        if (a.isPublished && !b.isPublished) return 1; // b comes first

        // Sort active items (assuming isActive is a boolean)
        if (a.isActive && !b.isActive) return -1; // a comes first
        if (!a.isActive && b.isActive) return 1; // b comes first

        // For availability
        if (a.isAvailable && !b.isAvailable) return -1; // a comes first
        if (!a.isAvailable && b.isAvailable) return 1; // b comes first

        // If all conditions are equal, maintain current order
        return 0;
      });

      // Grouping the products into arrays of three
      let repeat = (this.state.products && this.state.products.length > 0) ? ((3 - this.state.products.length % 3) % 3) : 0;
      if (repeat === 1) {
        products = [...products, products[0]];
      } else if (repeat === 2) {
        products = [...products, products[0], products[0]];
      }

      var productGroup = [];
      for (var i = 0; i < products.length; i++) {
        if (i % 3 === 0) {
          productGroup.push([]);
        }
        productGroup[productGroup.length - 1].push(products[i]);
      }
      this.setState({ products3: productGroup });
    });

    window.addEventListener('resize', this.handleResize);
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    let products = this.state.products;
    let width = this.state.width;
    let products3 = this.state.products3;
    const { t } = this.props;

    // Number of items to display on small screens
    const smallScreenItemCount = 3;

    return (
      <>
        <div className="d-md-none">
          <Fade>
            <div className="img-box img-box-home3">
              <div style={{ margin: "4% 9% 4% 9%" }}>
                <Fade bottom delay={200}>
                  <div>
                    <div className="d-content-center d-white fs-2" dangerouslySetInnerHTML={{ __html: t("Home3.eSTOKK PROPERTIES") }}></div>
                    <div className="d-content-center" style={{ fontSize: '0.7rem' }} dangerouslySetInnerHTML={{ __html: t("Home3.Build your real estate investment portfolio today") }}></div>

                    {width >= this.state.home3CriticalWidth ? (
                      // Render Carousel for larger screens
                      <Carousel
                        prevIcon={<img src="imgs/home/left.png" style={{ width: 20 }} />}
                        nextIcon={<img src="imgs/home/right.png" style={{ width: 20 }} />}
                      >
                        {products3.length === 0 ? (
                          <div className="text-center d-text-60 d-white"><h1>{t("Home3.There is no house.")}</h1></div>
                        ) : (
                          products3.map((productGroup, index) => (
                            <Carousel.Item key={index} interval={100000}>
                              <Layout.Row gutter="20">
                                {productGroup.map(product => (
                                  <Layout.Col key={product.id} md={8}>
                                    <div className="grid-content bg-purple" style={{ marginTop: (product.timerToShow ? '7px' : '') }}>
                                      <Product productData={product} />
                                    </div>
                                  </Layout.Col>
                                ))}
                              </Layout.Row>
                            </Carousel.Item>
                          ))
                        )}
                      </Carousel>
                    ) : (
                      // Render fixed number of items for smaller screens
                      <Layout.Row gutter="20">
                        {products.slice(0, smallScreenItemCount).map(product => (
                          <Layout.Col key={product.id} md={8}>
                            <div className="grid-content bg-purple" style={{ marginTop: (product.timerToShow ? '7px' : '') }}>
                              <Product productData={product} />
                            </div>
                          </Layout.Col>
                        ))}
                      </Layout.Row>
                    )}

                    <div className="d-content-center">
                      <Button
                        className="d-highlight-black-button d-text-36"
                        style={{ margin: '0 24%', width: "42%", backgroundColor: "#0dbfcd" }}
                        onClick={() => { window.location.href = "#marketplace"; }}
                      >
                        {t("Home3.VIEW ALL PROPERTIES")}
                      </Button>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </Fade>
        </div>
        <div className="d-none d-md-block">
          <Fade>
            <div className="img-box img-box-home3">
              <div style={{ margin: "4% 9% 4% 9%" }}>
                <Fade bottom delay={200}>
                  <div>
                    <div className="d-content-center d-white d-text-85" dangerouslySetInnerHTML={{ __html: t("Home3.eSTOKK PROPERTIES") }}>
                    </div>
                    <div className="d-content-center d-text-20" dangerouslySetInnerHTML={{ __html: t("Home3.Build your real estate investment portfolio today") }}>
                    </div>
                    <Carousel
                      prevIcon={<img src="imgs/home/left.png" style={{ width: 20 }} />}
                      nextIcon={<img src="imgs/home/right.png" style={{ width: 20 }} />}>

                      {
                        (!products || products.length === 0) ? <div className="text-center d-text-60 d-white"><h1>{t("Home3.There is no house.")}</h1></div>
                          : width < this.state.home3CriticalWidth ?
                            products.map(product => (
                              <Carousel.Item key={product.id} interval={100000}>
                                <Layout.Row gutter="20">
                                  <Layout.Col className='col-12'>
                                    <div className="grid-content bg-purple">
                                      <Product
                                        productData={product}
                                      />
                                    </div>
                                  </Layout.Col>
                                </Layout.Row>
                              </Carousel.Item>
                            ))
                            :
                            products3.map((productGroup) => (
                              <Carousel.Item interval={100000} style={{ paddingTop: 50 }}>
                                <Layout.Row gutter="20">
                                  {
                                    productGroup.map((product) => (
                                      <Layout.Col md={8}>
                                        <div className="grid-content bg-purple" style={{ marginTop: (product.timerToShow ? '7px' : '') }}>
                                          <Product
                                            productData={product}
                                          />
                                        </div>
                                      </Layout.Col>
                                    ))
                                  }
                                </Layout.Row>
                              </Carousel.Item>
                            ))
                      }
                    </Carousel>

                    <div className="d-content-center">
                      <Button className=" d-highlight-black-button d-text-36"
                        style={{ margin: '0 24%', width: "42%", backgroundColor: "#0dbfcd" }} onClick={() => { window.location.href = "#marketplace"; }}>
                        {t("Home3.VIEW ALL PROPERTIES")}
                      </Button>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </Fade>
        </div>

      </>
    );
  }
});

export default withTranslation()(Home3);
