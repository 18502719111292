import React, { useState, useEffect } from 'react';

const ComingSoonTimer = ({ updatedAt, hoursToPublish, isSmall }) => {
  const updatedAtDate = new Date(updatedAt);
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const targetTime = updatedAtDate.getTime() + hoursToPublish * 3600 * 1000;
  const releaseDate = new Date(targetTime);
  const currentTime = Date.now();
  const initialRemainingTime = targetTime - currentTime;
  const [remainingTime, setRemainingTime] = useState(initialRemainingTime);
  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1000);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [remainingTime]);

  if (remainingTime <= 0) {
    return <div></div>;
  }

  // Calculate hours, minutes, and seconds from remaining time
  const hours = Math.floor(remainingTime / (1000 * 60 * 60));
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  let hrs = releaseDate.getHours(); // returns 0-23

  // Determine AM or PM
  const ampm = hrs >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  hrs = hrs % 12;
  hrs = hrs ? hrs : 12; // The hour '0' should be '12'

  return (
    <div className='d-flex' style={{flex: 1, transform: isSmall ? `translateY(-4px)` : `translateY(-5px)`, alignItems: 'center', justifyContent: isSmall ? "space-between": "flex-start", gap: 5 }}>
      <span
        style={{
          width: isSmall ? "100%" : 160,
          height: isSmall ? 20 : 30,
          borderRadius: isSmall ? 5 : 10,
          borderColor: "#03ffa4",
          backgroundColor: "#006",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: isSmall ? 10 : 16
        }}
        className="d-font-bold"
      >
        {monthNames[releaseDate.getMonth()]} {releaseDate.getDate()}<span style={{ fontSize: isSmall ? 6 : 9 }}>TH</span>, {hrs + " " + ampm}
      </span>
      <span
        style={{
          width: isSmall ? "100%" : 160,
          height: isSmall ? 20 : 30,
          borderRadius: isSmall ? 5 : 10,
          borderColor: "#03ffa4",
          backgroundColor: "#00b4c9",
          color: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: isSmall ? 10 : 16
        }}
        className="d-font-bold"
      >
        {hours}h {minutes}m {seconds}s
      </span>
    </div>
  );
};

export default ComingSoonTimer;
