import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Layout } from "element-react";
import ImageLabel from "./ImageLabel";
import ColorLine from "./ColorLine";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../assets/Product.css";
import { Link } from "react-router-dom";
import TooltipIcon from "./Tooltip_i_icon";
import Timer from "./Timer";
import { withTranslation } from "react-i18next";
import { actionGetId } from "../redux/actions/property";
import { connect } from "react-redux";
import ComingSoonTimer from "./comingSoonTimer";
import comingSoon from '../assets/imgs/icons/comingSoon.png'

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = { actionGetId };

const Product = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tooltipOpen: false,
        isComingSoon: false,
      };
    }

    onClick(e) {
      let ID = this.props.productData.id;
      this.props.actionGetId(ID);
      console.log(this.props.productData);
    }
    setIsComingSoon() {
      const updatedAtDate = new Date(this.props.productData.updatedAt);
      const targetTime = updatedAtDate.getTime() + this.props.productData.hoursToPublish * 3600 * 1000;

      // Get the current time
      const currentTime = Date.now();

      // Calculate the remaining time in milliseconds
      const initialRemainingTime = targetTime - currentTime;
      this.setState({ isComingSoon: initialRemainingTime > 0 });
    }
    toggle = () => this.setState((oldState) => ({ ...oldState, tooltipOpen: !this.state.tooltipOpen }));

    componentDidMount() {
      console.log(this.state.isComingSoon)
      this.setIsComingSoon()
      console.log(this.state.isComingSoon)
    }

    render() {
      const img = `${this.props.productData.imageData[0]}`;
      const { isComingSoon } = this.state
      const address1_1 = this.props.productData.address1;
      const address2_1 = this.props.productData.address2;
      const str1 = address1_1.replaceAll(" ", "-");
      const str2 = address2_1.replaceAll(",", "");
      const str3 = str2.replaceAll(" ", "-");
      const minInvestment = Number(this.props.productData.tokenValue)
        .toFixed(2)
        .toLocaleString();
      const projectIRR = Number(this.props.productData.projectIRR)
        .toFixed(2)
        .toLocaleString();
      // const tokenPrice = Number(this.props.productData.tokenValue).toFixed(2).toLocaleString()
      // const rentPerToken = Number(this.props.productData.yearlyRentPerToken).toFixed(2).toLocaleString()
      const totalTokens = this.props.productData.generatedToken;
      const tokensAvaliable = this.props.productData.available;
      const available =
        tokensAvaliable > 0 ? tokensAvaliable : totalTokens + tokensAvaliable;
      // const available = 0;
      //		const invest = (this.props.productData.available) ? parseFloat(100 - (available * 100 / this.props.productData.generatedToken)).toFixed(2) : 0;

      //		const totalPrice = this.props.productData.totalInvestment.toFixed(2).toLocaleString()
      const expectedROI = Number(this.props.productData.expectedYield)
        .toFixed(2)
        .toLocaleString();
      let { timerToShow } = this.props.productData;
      let days = false;
      const propertyClass = this.props.productData.propertyClass;
      const mode = this.props.mode;

      const address1 = address1_1.toUpperCase();
      const address2 = address2_1.toUpperCase();

      if (timerToShow) {
        let splitted_with_day = String(timerToShow).split("Product.__");
        if (parseInt(splitted_with_day[0]) > 0) {
          days = parseInt(splitted_with_day[0]);
        }

        if (parseFloat(splitted_with_day[1]) > 0) {
          timerToShow = parseFloat(splitted_with_day[1]);
        } else {
          timerToShow = false;
        }
      }
      const { t } = this.props;

      console.log("Title: ", this.props.productData.address1 + "  " + this.props.productData.address2)
      console.log("Availability: ", available)

      return (
        <>
          {/* {timerToShow ? ( */}
          {/* <div
              style={{ width: "100%", textAlign: "center", marginBottom: -32 }}
            >
              <Timer timerToShow={timerToShow} days={days} />
            </div> */}
          {/* ) : null} */}
          {mode == "wide" ? (
            <Layout.Row>
              <Link
                to={`/product/${str1}-${str3}_${this.props.productData.id}`}
                onClick={this.onClick.bind(this)}
              >
                <Layout.Col span="24">
                  {available > 0 ? (
                    <>
                      {
                        isComingSoon ? <>
                          <div className="d-flex d-md-none items-center mt-2 content-center">
                            <img
                              src={comingSoon}
                              className="img-fluid mb-1 mr-1"
                              style={{ width: "30%" }}
                            ></img>
                            <ComingSoonTimer isSmall={true} setIsComingSoon={this.setIsComingSoon} updatedAt={this.props.productData.updatedAt} hoursToPublish={this.props.productData.hoursToPublish} />
                          </div>
                          <div className="d-none d-md-flex items-center content-center">
                            <img
                              src={comingSoon}
                              className="img-fluid"
                              style={{ width: "15%" }}
                            ></img>
                            <ComingSoonTimer setIsComingSoon={this.setIsComingSoon} updatedAt={this.props.productData.updatedAt} hoursToPublish={this.props.productData.hoursToPublish} />
                          </div>
                        </> : <>
                          <img
                            src="/imgs/marketplace/1.png"
                            className="d-none d-md-block img-fluid"
                            style={{ width: "15%" }}
                          ></img>
                          <img
                            src="/imgs/marketplace/1.png"
                            className="d-md-none img-fluid"
                            style={{ width: "30%" }}
                          ></img>

                        </>

                      }

                    </>
                  ) : (
                    <>
                      <img
                        src="/imgs/marketplace/2.png"
                        className="d-md-none img-fluid"
                        style={{ width: "30%" }}
                      ></img>
                      <img
                        src="/imgs/marketplace/2.png"
                        className="d-none d-md-block img-fluid"
                        style={{ width: "15%" }}
                      ></img>

                    </>
                  )}

                  <div
                    className="grid-content bg-purple"
                    style={{ position: "relative", display: "contents" }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <img
                        id={"house-image-ref"}
                        width={"100%"}
                        style={{
                          height: 380,
                          position: "absolute",
                          left: 0,
                          objectFit: "cover",
                        }}
                        src={`${process.env.REACT_APP_API_ENDPOINT}/public/${img}`}
                        alt=""
                        // onClick={() => {window.location.href = `/product/${str1}-${str3}`;}}
                        className="marketplace-img"
                      />
                    </div>

                    <div
                      style={{
                        backgroundColor: "#234e70",
                        padding: "6px 27px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        width: "100%",
                      }}
                    >
                      <img src="/image/market_place/map.png" alt="" />
                      &nbsp;
                      <span
                        className="d-font-bold d-text-36"
                        style={{ color: "white" }}
                      >
                        &nbsp;{address1}
                      </span>{" "}
                      &nbsp; &nbsp;
                      <span className="d-highlight d-font-bold d-text-36">
                        {address2}
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: 45,
                        marginLeft: 15,
                        position: "absolute",
                        backgroundColor: "#234e70",
                        padding: "2px 0px",
                        width: 100,
                        borderRadius: 5,
                      }}
                    >
                      <img
                        src="/imgs/marketplace/home.png"
                        className="img-fluid ml-2"
                      ></img>
                      <span
                        className="d-text-36 ml-2"
                        style={{ color: "#0dbfcd" }}
                      >
                        {propertyClass}
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: 290,
                        marginLeft: 40,
                        position: "absolute",
                        display: "grid",
                        placeContent: 'center'
                      }}
                    >
                      <img width={"40%"} src="/imgs/marketplace/mark.png"></img>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 4,
                        marginBottom: 30,
                      }}
                    >
                      <Row style={{ marginTop: 380 }}>
                        <Col md={4}>
                          <div
                            style={{
                              backgroundColor: "#fff",
                              padding: "6px 0px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <ImageLabel
                              img="/image/market_place/minimum.png"
                              font="SourceSansPro-Bold"
                              fontSize={14}
                              padding={6}
                              label={`${t("Product.Minimum Investment")}:`}
                              color="#234e70"
                            />
                            <div className="d-highlight d-font-bold d-text-60">
                              {"$" + minInvestment}
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div
                            style={{
                              backgroundColor: "#fff",
                              padding: "6px 0px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <ImageLabel
                              img="/image/market_place/1.png"
                              font="SourceSansPro-Bold"
                              fontSize={14}
                              padding={6}
                              label={`${t("Product.EXPECTED INCOME / ROI")}:`}
                              color="#234e70"
                            >
                              <TooltipIcon
                                width={15}
                                content={
                                  <>
                                    <strong>Expected yield</strong> is expressed
                                    as the{" "}
                                    <a href="https://www.investopedia.com/terms/c/capitalizationrate.asp">
                                      Cap Rate
                                    </a>{" "}
                                    (capitalization rate), the ratio of net
                                    operating income to the current value or
                                    sale price of a property. It determines the
                                    potential return on an investment.
                                  </>
                                }
                                img={"/image/market_place/2.png"}
                              />
                            </ImageLabel>

                            <div className="d-black d-font-bold d-text-60">
                              {expectedROI + "%"}
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div
                            style={{
                              backgroundColor: "#fff",
                              padding: "6px 0px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <ImageLabel
                              img="/imgs/home/3/projectIRR.png"
                              font="SourceSansPro-Bold"
                              fontSize={14}
                              padding={6}
                              label={`${t("Product.Project IRR")}:`}
                              color="#234e70"
                            >
                              <TooltipIcon
                                width={15}
                                content={
                                  <>
                                    <strong>Expected yield</strong> is expressed
                                    as the{" "}
                                    <a href="https://www.investopedia.com/terms/c/capitalizationrate.asp">
                                      Cap Rate
                                    </a>{" "}
                                    (capitalization rate), the ratio of net
                                    operating income to the current value or
                                    sale price of a property. It determines the
                                    potential return on an investment.
                                  </>
                                }
                                img={"/image/market_place/2.png"}
                              />
                            </ImageLabel>

                            <div className="d-highlight d-font-bold d-text-60">
                              {projectIRR + "%"}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {available > 0 ? (
                        <>
                          {isComingSoon ?
                            <Link
                              to={`/product/${str1}-${str3}_${this.props.productData.id}`}
                              onClick={this.onClick.bind(this)}
                              className="text-decoration-none"
                            >
                              <div
                                style={{
                                  backgroundColor: "#0dbfcd",
                                  padding: "6px 24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  borderBottom: "2px solid #0dbfcd",
                                  flexWrap: "wrap",
                                  cursor: "pointer"
                                }}
                              >
                                <div
                                  className="d-font-bold d-text-48"
                                  style={{ color: "#234e70" }}
                                >
                                  {t("Product.Coming Soon")}
                                </div>
                              </div>
                            </Link> :
                            <Link
                              to={`/product/${str1}-${str3}_${this.props.productData.id}`}
                              onClick={this.onClick.bind(this)}
                              className="text-decoration-none"
                            >
                              <div
                                style={{
                                  backgroundColor: "#0dbfcd",
                                  padding: "6px 24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  borderBottom: "2px solid #0dbfcd",
                                  flexWrap: "wrap",
                                  cursor: "pointer"
                                }}
                              >
                                <div
                                  className="d-font-bold d-text-48"
                                  style={{ color: "#234e70" }}
                                >
                                  {t("Product.AVAILABLE")}
                                </div>

                                <div className="d-black d-font-bold d-text-48">
                                  <span style={{ color: "white" }}>
                                    {`${t("Product.STOCK")}:`}
                                  </span>
                                  &nbsp;&nbsp;
                                  <span className="d-font-bold" style={{ color: "#234e70" }}>
                                    {available}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          }
                        </>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#234e70",
                            padding: "6px 24px",
                          }}
                        >
                          <div
                            className="d-font-bold d-text-48 text-center"
                            style={{ color: "#0dbfcd" }}
                          >
                            {t("Product.SOLD OUT")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Layout.Col>
              </Link>
            </Layout.Row>
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  borderRadius: 4,
                  marginTop: 5,
                  marginBottom: 30,
                  overflowY: "visible",
                  position: "relative",
                }}
              >
                {available > 0 ? (
                  <>
                    {
                      isComingSoon ? <div style={{ position: "absolute", top: -35, zIndex: 99, alignItems: "center", justifyContent: "space-between" }} className="d-flex gap-1">
                        <img
                          src={comingSoon}
                          className="img-fluid"
                          style={{ width: "30%", flex: 0.5 }}
                        ></img>
                        <ComingSoonTimer isSmall={true} setIsComingSoon={this.setIsComingSoon} updatedAt={this.props.productData.updatedAt} hoursToPublish={this.props.productData.hoursToPublish} />
                      </div> : <>
                      </>

                    }

                  </>
                ) : (""
                )}
                <div>
                  <Link
                    to={`/product/${str1}-${str3}_${this.props.productData.id}`}
                    onClick={this.onClick.bind(this)}
                  >
                    {/* <Link
                  to={`_${this.props.productData.id}`}
                  onClick={this.onClick.bind(this)}
                > */}
                    <img
                      style={{
                        height: "30vh",
                        width: "100%",
                        position: "absolute",
                        left: 0,
                        objectFit: "cover",
                      }}
                      src={`${process.env.REACT_APP_API_ENDPOINT}/public/${img}`}
                      alt=""
                    />
                  </Link>
                  <div
                    style={{
                      marginTop: 25,
                      marginLeft: 15,
                      backgroundColor: "#234e70",
                      width: 80,
                      borderRadius: 5,
                      position: "absolute",
                      padding: "4px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="/imgs/marketplace/home.png"
                      className="img-fluid"
                      style={{ width: "16px" }}
                    ></img>
                    <span className="d-text-36 ml-2" style={{ color: "#0dbfcd" }}>
                      {propertyClass}
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "#234e70",
                    padding: "6px 27px",
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "30vh",
                  }}
                >
                  <img src="/image/market_place/map.png" alt="" />
                  &nbsp;
                  <span className="d-font-bold" style={{ color: "white" }}>
                    &nbsp;{address1 + ","}
                  </span>{" "}
                  &nbsp; &nbsp;
                  <span className="d-highlight d-font-bold ">{address2.replace(",", " ")}</span>
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "6px 24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "2px solid #0dbfcd",
                  }}
                >
                  <ImageLabel
                    img="/imgs/home/3/minInvestment.png"
                    font="SourceSansPro-Bold"
                    fontSize={18}
                    padding={6}
                    label={`${t("Product.Minimum Investment")}:`}
                    color="#234e70"
                  />
                  <div className="d-highlight d-font-book d-text-60">
                    {"$" + minInvestment}
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "6px 24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "2px solid #0dbfcd",
                  }}
                >
                  <ImageLabel
                    img="/image/market_place/1.png"
                    font="SourceSansPro-Bold"
                    fontSize={18}
                    padding={6}
                    label={`${t("Product.EXPECTED INCOME / ROI")}:`}
                    color="#234e70"
                  >
                    <TooltipIcon
                      width={15}
                      content={
                        <>
                          <strong>Expected yield</strong> is expressed as the{" "}
                          <a href="https://www.investopedia.com/terms/c/capitalizationrate.asp">
                            Cap Rate
                          </a>{" "}
                          (capitalization rate), the ratio of net operating income
                          to the current value or sale price of a property. It
                          determines the potential return on an investment.
                        </>
                      }
                      img={"/image/market_place/2.png"}
                    />
                  </ImageLabel>

                  <div
                    className="d-font-book d-text-60"
                    style={{ color: "#234e70" }}
                  >
                    {expectedROI + "%"}
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "6px 24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "2px solid #0dbfcd",
                  }}
                >
                  <ImageLabel
                    img="/imgs/home/3/projectIRR.png"
                    font="SourceSansPro-Bold"
                    fontSize={18}
                    padding={6}
                    label={`${t("Product.Project IRR")}:`}
                    color="#234e70"
                  >
                    <TooltipIcon
                      width={15}
                      content={
                        <>
                          <strong>Expected yield</strong> is expressed as the{" "}
                          <a href="https://www.investopedia.com/terms/c/capitalizationrate.asp">
                            Cap Rate
                          </a>{" "}
                          (capitalization rate), the ratio of net operating income
                          to the current value or sale price of a property. It
                          determines the potential return on an investment.
                        </>
                      }
                      img={"/image/market_place/2.png"}
                    />
                  </ImageLabel>

                  <div
                    className="d-font-book d-text-60"
                    style={{ color: "#234e70" }}
                  >
                    {projectIRR + "%"}
                  </div>
                </div>
                {available > 0 ? (
                  <>
                    {isComingSoon ?
                      <Link
                        to={`/product/${str1}-${str3}_${this.props.productData.id}`}
                        onClick={this.onClick.bind(this)}
                        className="text-decoration-none"
                      >
                        <div
                          style={{
                            backgroundColor: "#0dbfcd",
                            padding: "6px 24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            borderBottom: "2px solid #0dbfcd",
                            flexWrap: "wrap",
                            cursor: "pointer"
                          }}
                        >
                          <div
                            className="d-font-bold d-text-48"
                            style={{ color: "#234e70" }}
                          >
                            {t("Product.Coming Soon")}
                          </div>
                        </div>
                      </Link> :
                      <Link
                        to={`/product/${str1}-${str3}_${this.props.productData.id}`}
                        onClick={this.onClick.bind(this)}
                        className="text-decoration-none"
                      >
                        <div
                          style={{
                            backgroundColor: "#0dbfcd",
                            padding: "6px 24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            borderBottom: "2px solid #0dbfcd",
                            flexWrap: "wrap",
                            cursor: "pointer"
                          }}
                        >
                          <div
                            className="d-font-bold d-text-48"
                            style={{ color: "#234e70" }}
                          >
                            {t("Product.AVAILABLE")}
                          </div>

                          <div className="d-black d-font-bold d-text-48">
                            <span style={{ color: "white" }}>
                              {`${t("Product.STOCK")}:`}
                            </span>
                            &nbsp;&nbsp;
                            <span className="d-font-bold" style={{ color: "#234e70" }}>
                              {available}
                            </span>
                          </div>
                        </div>
                      </Link>
                    }
                  </>
                ) : (
                  <Link
                    to={`/product/${str1}-${str3}_${this.props.productData.id}`}
                    onClick={this.onClick.bind(this)}
                    className="text-decoration-none"
                  >
                    <div
                      style={{
                        backgroundColor: "#234e70",
                        padding: "6px 24px",
                      }}
                    >
                      <div
                        className="d-font-bold d-text-48 text-center"
                        style={{ color: "#0dbfcd" }}
                      >
                        {t("Product.SOLD OUT")}
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </>
          )}
        </>
      );
    }
  }
);

export default withTranslation()(Product);
